exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dogadjaji-js": () => import("./../../../src/pages/dogadjaji.js" /* webpackChunkName: "component---src-pages-dogadjaji-js" */),
  "component---src-pages-galerija-js": () => import("./../../../src/pages/galerija.js" /* webpackChunkName: "component---src-pages-galerija-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-meni-js": () => import("./../../../src/pages/meni.js" /* webpackChunkName: "component---src-pages-meni-js" */),
  "component---src-pages-muzika-js": () => import("./../../../src/pages/muzika.js" /* webpackChunkName: "component---src-pages-muzika-js" */),
  "component---src-pages-o-nama-js": () => import("./../../../src/pages/o-nama.js" /* webpackChunkName: "component---src-pages-o-nama-js" */),
  "component---src-pages-restoran-js": () => import("./../../../src/pages/restoran.js" /* webpackChunkName: "component---src-pages-restoran-js" */),
  "component---src-pages-vencanja-js": () => import("./../../../src/pages/vencanja.js" /* webpackChunkName: "component---src-pages-vencanja-js" */),
  "component---src-pages-vinska-i-koktel-karta-js": () => import("./../../../src/pages/vinska-i-koktel-karta.js" /* webpackChunkName: "component---src-pages-vinska-i-koktel-karta-js" */)
}

